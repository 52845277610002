<div class="banner" role="banner" *ifScreenSize="screenSize.Desktop">
  <h1 class="text-uppercase">{{ 'home.page.title' | translate }} {{ name$ | async }}</h1>
</div>

<ng-container *ifScreenSize="screenSize.Mobile">
  <div class="px-1 bg-primary">
    <fgb-barcode [displayQrCode]="true" [displayWallet]="false"></fgb-barcode>
  </div>
</ng-container>

<!-- <div class="home-nav w-100" *ifScreenSize="screenSize.Desktop">
  <fgb-home-nav-bar></fgb-home-nav-bar>
</div> -->

<div class="container">
  <div class="info d-flex flex-row justify-content-between align=items-center mt-4" *ifScreenSize="screenSize.Desktop">
    <fgb-member-card class="info-item"></fgb-member-card>

    <div class="">
      <img class="info-item" src="assets/images/home-widget-bg.jpg" alt="" />
    </div>
  </div>

  <!-- Announcements -->
  <fgbcl-announcements></fgbcl-announcements>
</div>
