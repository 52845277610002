<h2 *ngIf="ecashView" class="text-black mb-4 d-lg-block d-none">
  {{ 'payment.history.page.title' | translate }}
</h2>

<div class="card border mt-3 mt-lg-0">
  <div [ngClass]="{ 'card-body-ecash': ecashView, 'card-body-account': accountView }">
    <div class="d-flex flex-column flex-lg-row justify-content-lg-between">
      <fgbcl-date-range-picker [(startDate)]="transactionStartDate" [(endDate)]="transactionEndDate"></fgbcl-date-range-picker>
      <button type="button" class="search-button rounded fw-bold mb-2 d-block text-white" (click)="search()">Search</button>
    </div>
  </div>
</div>

<fgb-transaction-list
  *ngIf="selectedValues"
  [startDate]="selectedValues.start"
  [endDate]="selectedValues.end"
  [memberId]="memberId"
  [transactionType]="selectedValues.type"
></fgb-transaction-list>
