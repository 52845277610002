<ul class="nav nav-secondary nav-bar">
  <a
    class="nav-item fw-bold primary-theme py-0"
    [routerLink]="['']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <!-- <label>{{ 'navbar.payment' | translate }}</label> -->
    <label>HOME</label>
  </a>
  <a
    class="nav-item fw-bold primary-theme py-0"
    [routerLink]="['/payment']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <!-- <label>{{ 'navbar.ecash.history' | translate }}</label> -->
    <label>PAYMENT</label>
  </a>
</ul>
