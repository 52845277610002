import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  PopulationGuard,
  // StepType,
  MemberResolver,
  LottoResolver,
  AuctionResolver,
  MemberCardResolver,
  BalanceResolver,
  PurchaseResolver,
  CardTokenResolver,
  FantasyGameResolver,
  SurveyResolver,
  LottoWalletResolver,
  AuctionBidsResolver,
  FantasyHistoryResolver,
  BenefitResolver,
  AddressResolver,
  TransactionSuccessGuard,
  SeatingDetailResolver,
  CountryResolver,
  MemberLoyaltyStatusResolver,
  PortalProductsSubscriptionsResolver,
  PopUpNotificationResolver,
  MaintenanceGuard,
  // FGBPreLoadingStrategy,
  firstTimeUserGuard,
  FGBPreLoadingStrategy,
  groupedWalletResolver,
} from '@fgb/core';

// General
import { MasterPageComponent } from './pages/master/master-page/master-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
// Loyalty
import { RewardsLayoutComponent } from './pages/rewards-layout/rewards-layout.component';
import { RewardsPageComponent } from './pages/rewards-page/rewards-page.component';
import { AuctionsPageComponent } from './pages/auctions-page/auctions-page.component';
import { AuctionDetailPageComponent } from './pages/auction-detail-page/auction-detail-page.component';
import { MarketplacePageComponent } from './pages/marketplace-page/marketplace-page.component';
import { MarketplaceDetailPageComponent } from './pages/marketplace-detail-page/marketplace-detail-page.component';
import { MarketplacePurchaseSuccessPageComponent } from './pages/marketplace-page/marketplace-purchase-success-page/marketplace-purchase-success-page.component';
import { LottosPageComponent } from './pages/lottos-page/lottos-page.component';
import { LottoDetailPageComponent } from './pages/lotto-detail-page/lotto-detail-page.component';
import { LottosSuccessPageComponent } from './pages/lottos-page/lottos-success-page/lottos-success-page.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { WalletPageComponent } from './pages/wallet-page/wallet-page.component';
import { GamesLayoutComponent } from './pages/games-layout/games-layout.component';
import { GamesPageComponent } from './pages/games-page/games-page.component';
import { FantasyGamesPageComponent } from './pages/fantasy-games-page/fantasy-games-page.component';
import { FantasyPlayerSelectPageComponent } from './pages/fantasy-player-select-page/fantasy-player-select-page.component';
import { PredictorPageComponent } from './pages/predictor-page/predictor-page.component';
import { PollsPageComponent } from './pages/polls-page/polls-page.component';
import { AuctionWalletDetailsPageComponent } from './pages/auction-wallet-details-page/auction-wallet-details-page.component';
import { LottoWalletDetailsPageComponent } from './pages/lotto-wallet-details-page/lotto-wallet-details-page.component';
import { QuizPageComponent } from './pages/quiz-page/quiz-page.component';
import { ScorePredictorPageComponent } from './pages/score-predictor-page/score-predictor-page.component';
import { ProductsPageComponent } from './pages/products-page/products-page.component';
import { BadgesPageComponent } from './pages/badges-page/badges-page.component';

// Account
import { AccountDetailsPageComponent } from './pages/account-details-page/account-details-page.component';
import { ActivityPageComponent } from './pages/activity-page/activity-page.component';
import { GuardianPageComponent } from './pages/guardian-page/guardian-page.component';
import { CardPageComponent } from './pages/card-page/card-page.component';
// Ecash
import { EcashPageComponent } from './pages/ecash-page/ecash-page.component';
import { EcashSuccessPageComponent } from './pages/ecash-page/ecash-success-page/ecash-success-page.component';
import { EcashFailurePageComponent } from './pages/ecash-page/ecash-failure-page/ecash-failure-page.component';
import { EcashResultPageComponent } from './pages/ecash-page/ecash-result-page/ecash-result-page.component';
import { EcashHistoryPageComponent } from './pages/ecash-history/ecash-history.component';
// Portal Products
import { PortalProductSuccessPageComponent } from './pages/portal-products-pages/portal-product-success-page/portal-product-success-page.component';
import { PortalProductFailurePageComponent } from './pages/portal-products-pages/portal-product-failure-page/portal-product-failure-page.component';
import { PortalProductResultsPageComponent } from './pages/portal-products-pages/portal-product-results-page/portal-product-results-page.component';

// Unauthenticated
import { AuthCallbackComponent } from './modules/user/components/auth/auth-callback/auth-callback.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LoginAccountsPageComponent } from './pages/login-accounts-page/login-accounts-page.component';
import { SingleSignOnComponent } from './modules/user/components/single-sign-on/single-sign-on.component';
import { MaintenancePageComponent } from './pages/maintenance-page/maintenance-page.component';
// Club Pages
import { EarnPageComponent } from './pages/earn-page/earn-page.component';
import { BenefitsPageComponent } from './pages/benefits-page/benefits-page.component';
import { LottosTermsPageComponent } from './pages/lottos-terms-page/lottos-terms-page.component';
import { MarketplaceTermsPageComponent } from './pages/marketplace-terms-page/marketplace-terms-page.component';
import { TmCallbackComponent } from './shared/components/manage-tickets/tm-callback/tm-callback.component';
import { TmAuthCallbackComponent } from './shared/components/providers/tm/tm-auth-callback/tm-auth-callback.component';
import { WorksheetPageComponent } from './pages/worksheet-page/worksheet-page.component';
import { FantasyLeaderboardPageComponent } from './pages/fantasy-leaderboard-page/fantasy-leaderboard-page.component';
import { V1SingleSignOnComponent } from './modules/user/components/v1-single-sign-on/v1-single-sign-on.component';
import { SubscriptionsPageComponent } from './pages/subscriptions-page/subscriptions-page.component';
import { CartCheckoutPagePortalProductsComponent } from './pages/cart-checkout-page-portal-products/cart-checkout-page-portal-products.component';
import { CartCheckoutPageComponent } from './pages/cart-checkout-page/cart-checkout-page.component';
// Offer Pages
import { CartCheckoutResultsPageComponent } from './pages/cart-checkout-page/cart-checkout-results-page/cart-checkout-results-page.component';
import { CartCheckoutSuccessPageComponent } from './pages/cart-checkout-page/cart-checkout-success-page/cart-checkout-success-page.component';
import { SportsAllianceCallbackComponent } from './shared/components/providers/sports-alliance/sports-alliance-callback/sports-alliance-callback.component';
import { PaymentPageComponent } from './pages/payment-page/payment-page.component';
import { ProviderCallbackComponent } from './shared/components/providers/provider-callback/provider-callback.component';
import { KeyCloakCallbackComponent } from './shared/components/providers/key-cloak/key-cloak-callback/key-cloak-callback.component';
import { SeatGeekCallbackComponent } from './shared/components/providers/seatgeek/seatgeek-callback/seatgeek-callback.component';
import { OtherMediaCallbackComponent } from './shared/components/providers/other-media/other-media-callback/other-media-callback.component';
import { UserResetPasswordComponent } from './modules/user/components/user-reset-password/user-reset-password.component';
import { UserChangePasswordComponent } from './modules/user/components/user-change-password/user-change-password.component';
import { CampaignDetailsPageComponent } from './pages/campaign-details-page/campaign-details-page.component';
import { RegistrationLayoutPageComponent } from './modules/registration/pages/registration-layout-page/registration-layout-page.component';
import { WalletClaimedPageComponent } from './pages/wallet-claimed-page/wallet-claimed-page.component';
import { RegistrationPaymentModule, provideRegistrationConfig } from '@fgb/portal-component-library/src/lib/registration';
import { MarketplaceWalletPageComponent } from './pages/marketplace-wallet-page/marketplace-wallet-page.component';
import { ChallengesPageComponent } from './pages/challenges-page/challenges-page.component';
import { ScorecardDescriptionComponent } from '@fgb/portal-component-library/src/lib/loyalty/scorecards';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, firstTimeUserGuard()],
    canActivateChild: [MaintenanceGuard],
    component: MasterPageComponent,
    resolve: {
      member: MemberResolver,
      memberCard: MemberCardResolver,
      balances: BalanceResolver,
      popupNotifications: PopUpNotificationResolver,
    },
    children: [
      {
        path: '',
        canActivate: [PopulationGuard],
        data: {
          exclude: ['testPop'],
          popRedirect: 'test',
        },
        component: HomePageComponent,
      },
      {
        path: 'guardians',
        component: GuardianPageComponent,
        resolve: {
          country: CountryResolver,
          address: AddressResolver,
        },
      },

      {
        path: 'cards',
        component: CardPageComponent,
      },
      {
        path: 'worksheet',
        component: WorksheetPageComponent,
      },
      {
        path: 'rewards',
        component: RewardsLayoutComponent,
        children: [
          {
            path: '',
            component: RewardsPageComponent,
          },
          {
            path: 'auctions',
            children: [
              {
                path: '',
                resolve: { auctions: AuctionResolver },
                component: AuctionsPageComponent,
              },
              {
                path: 'details/:id',
                resolve: {
                  balances: BalanceResolver,
                  auctions: AuctionResolver,
                },
                component: AuctionDetailPageComponent,
              },
            ],
          },
          {
            path: 'marketplace',
            children: [
              {
                path: '',
                component: MarketplacePageComponent,
              },
              {
                path: 'details/:id',
                children: [
                  {
                    path: '',
                    resolve: {
                      balances: BalanceResolver,
                      cardTokens: CardTokenResolver,
                      country: CountryResolver,
                    },
                    component: MarketplaceDetailPageComponent,
                  },
                  {
                    path: 'terms',
                    component: MarketplaceTermsPageComponent,
                  },
                ],
              },
              {
                path: 'purchase/success',
                canActivate: [TransactionSuccessGuard],
                component: MarketplacePurchaseSuccessPageComponent,
                resolve: {
                  balances: BalanceResolver,
                  purchase: PurchaseResolver,
                },
              },
            ],
          },
          {
            path: 'events',
            component: EventsPageComponent,
          },
          {
            path: 'products',
            component: ProductsPageComponent,
          },
          {
            path: 'raffles',
            children: [
              {
                path: '',
                component: LottosPageComponent,
                resolve: { lottos: LottoResolver, balances: BalanceResolver },
              },
              {
                path: 'details/:id',
                children: [
                  {
                    path: '',
                    resolve: { balances: BalanceResolver, lottos: LottoResolver },
                    component: LottoDetailPageComponent,
                  },
                  {
                    path: 'terms',
                    component: LottosTermsPageComponent,
                    resolve: { lottos: LottoResolver },
                  },
                ],
              },
              {
                path: 'purchase/success',
                canActivate: [TransactionSuccessGuard],
                component: LottosSuccessPageComponent,
              },
            ],
          },
          {
            path: 'wallet',
            children: [
              {
                path: '',
                component: WalletPageComponent,
              },
              {
                path: 'details/:id',
                component: MarketplaceWalletPageComponent,
                resolve: { purchases: PurchaseResolver },
              },
              {
                path: 'raffledetails/:id',
                component: LottoWalletDetailsPageComponent,
                resolve: { lottoWalletItems: LottoWalletResolver },
              },
            ],
            resolve: {
              walletItems: groupedWalletResolver({ redeemed: false }),
            },
          },
          {
            path: 'claimed',
            children: [
              {
                path: '',
                component: WalletClaimedPageComponent,
                resolve: {
                  purchases: PurchaseResolver,
                  lottoWalletItems: LottoWalletResolver,
                  auctionBids: AuctionBidsResolver,
                },
              },
            ],
            resolve: {
              walletItems: groupedWalletResolver({ redeemed: true }),
            },
          },
          {
            path: 'bids',
            children: [
              {
                path: 'details/:id',
                component: AuctionWalletDetailsPageComponent,
                resolve: { bids: AuctionBidsResolver },
              },
            ],
            resolve: {
              walletItems: groupedWalletResolver({ redeemed: true }),
            },
          },
        ],
      },
      {
        path: 'account',
        component: AccountDetailsPageComponent,
        resolve: {
          member: MemberResolver,
          balances: BalanceResolver,
          seating: SeatingDetailResolver,
          memberLoyaltyStatus: MemberLoyaltyStatusResolver,
        },
      },
      { path: 'activity', component: ActivityPageComponent },
      {
        path: 'payment',
        resolve: { balances: BalanceResolver, cardTokens: CardTokenResolver },
        children: [{ path: '', component: PaymentPageComponent }],
      },

      {
        path: 'ecash',
        resolve: { balances: BalanceResolver, cardTokens: CardTokenResolver },
        children: [
          { path: '', component: EcashPageComponent },
          {
            path: 'success',
            resolve: { balances: BalanceResolver },
            component: EcashSuccessPageComponent,
          },
          { path: 'failure', component: EcashFailurePageComponent },
          { path: 'result', component: EcashResultPageComponent },
        ],
      },
      {
        path: 'ecashhistory',
        resolve: {
          balances: BalanceResolver,
          cardTokens: CardTokenResolver,
          memberCard: MemberCardResolver,
          member: MemberResolver,
        },
        component: EcashHistoryPageComponent,
      },
      {
        path: 'portalproducts',
        resolve: { balances: BalanceResolver, cardTokens: CardTokenResolver },
        children: [
          {
            path: 'success',
            resolve: { balances: BalanceResolver },
            component: PortalProductSuccessPageComponent,
          },
          { path: 'failure', component: PortalProductFailurePageComponent },
          { path: 'result', component: PortalProductResultsPageComponent },
        ],
      },
      {
        path: 'cardtoken',
        children: [
          { path: 'success', component: EcashSuccessPageComponent },
          { path: 'failure', component: EcashFailurePageComponent },
          { path: 'result', component: EcashResultPageComponent },
        ],
      },
      {
        path: 'campaign',
        children: [
          {
            path: 'details/:offerId',
            component: CampaignDetailsPageComponent,
          },
        ],
      },
      { path: 'faq', component: FaqPageComponent },
      { path: 'terms', component: TermsPageComponent },
      { path: 'contact', component: ContactUsPageComponent },
      { path: 'badges', component: BadgesPageComponent },
      { path: 'challenges', component: ChallengesPageComponent },
      { path: 'scorecard-description/:id', component: ScorecardDescriptionComponent },
      {
        path: 'games',
        resolve: {
          surveys: SurveyResolver,
          games: FantasyGameResolver,
        },
        component: GamesLayoutComponent,
        children: [
          { path: '', component: GamesPageComponent },
          {
            path: 'pickaplayer',
            component: FantasyGamesPageComponent,
            resolve: { games: FantasyGameResolver, history: FantasyHistoryResolver },
          },
          {
            path: 'pickaplayer/:id',
            component: FantasyPlayerSelectPageComponent,
            resolve: { games: FantasyGameResolver },
          },
          { path: 'predictor', component: PredictorPageComponent },
          { path: 'scorepredictor', component: ScorePredictorPageComponent },
          { path: 'polls', component: PollsPageComponent },
          { path: 'quiz', component: QuizPageComponent },
        ],
      },
      // Club Pages
      {
        path: 'checkout',
        children: [
          {
            path: '',
            component: CartCheckoutPageComponent,
            resolve: {
              member: MemberResolver,
              address: AddressResolver,
              country: CountryResolver,
            },
          },
          {
            path: 'success',
            component: CartCheckoutSuccessPageComponent,
          },
          {
            path: 'results',
            component: CartCheckoutResultsPageComponent,
          },
        ],
        resolve: {
          address: AddressResolver,
        },
      },
      {
        path: 'subscriptions',
        children: [
          {
            path: '',
            component: SubscriptionsPageComponent,
            resolve: {
              subscriptions: PortalProductsSubscriptionsResolver,
              address: AddressResolver,
            },
          },
          {
            path: 'checkout',
            component: CartCheckoutPagePortalProductsComponent,
            resolve: {
              subscriptions: PortalProductsSubscriptionsResolver,
              address: AddressResolver,
              country: CountryResolver,
              member: MemberResolver,
            },
          },
        ],
      },
      { path: 'earn', component: EarnPageComponent },
      {
        path: 'benefits',
        component: BenefitsPageComponent,
        resolve: { benefits: BenefitResolver },
      },
      {
        path: 'fantasyleaderboard',
        component: FantasyLeaderboardPageComponent,
      },
      { path: '404page', component: NotFoundPageComponent },
      { path: 'signin-ticketmaster', component: TmCallbackComponent },
      {
        path: 'provider/:providerid',
        component: ProviderCallbackComponent,
      },
    ],
  },

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent, canActivate: [MaintenanceGuard] },
  { path: 'login/accounts', component: LoginAccountsPageComponent, canActivate: [MaintenanceGuard] },
  { path: 'reset-password', component: UserResetPasswordComponent },
  { path: 'change-password', component: UserChangePasswordComponent },
  {
    path: 'register',
    component: RegistrationLayoutPageComponent,
    loadChildren: () => import('@fgb/portal-component-library/src/lib/registration').then((m) => m.RegistrationModule),
    providers: [provideRegistrationConfig()],
  },
  { path: 'callback', component: AuthCallbackComponent },
  { path: 'sso', component: SingleSignOnComponent },
  { path: 'login/singleSignOn/:providerId', component: SingleSignOnComponent },
  { path: 'login/singleSignOn/:providerId/:clubId', component: SingleSignOnComponent },
  { path: 'login/ss/login.aspx', component: V1SingleSignOnComponent },
  { path: 'maintenance', component: MaintenancePageComponent },
  { path: 'tm-auth-callback', component: TmAuthCallbackComponent },
  { path: 'sa-callback', component: SportsAllianceCallbackComponent },
  { path: 'kc-callback', component: KeyCloakCallbackComponent },
  { path: 'om-callback', component: OtherMediaCallbackComponent },
  { path: 'sg-callback', component: SeatGeekCallbackComponent },
  { path: '**', redirectTo: '404page' },
];

@NgModule({
  imports: [
    RegistrationPaymentModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: FGBPreLoadingStrategy,
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
