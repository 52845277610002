<div class="banner" role="banner" *ifScreenSize="!screenType.Mobile">
  <h1>{{ name$ | async }}</h1>
</div>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="px-1 bg-primary member-card-container">
    <fgb-barcode [cardToken]="defaultCardToken$ | async" [displayWallet]="false"></fgb-barcode>
  </div>
</ng-container>

<!-- <fgb-account-nav></fgb-account-nav> -->

<div class="container d-flex justify-content-center">
  <div class="account-card" *ifScreenSize="!screenType.Mobile">
    <h5 class="mt-lg-0 text-uppercase">{{ 'account.member.card.title' | translate }}</h5>

    <fgb-member-card></fgb-member-card>
  </div>
  <div class="account-info">
    <h5 class="mt-lg-0">{{ 'account.details.title' | translate }}</h5>

    <fgb-account-details></fgb-account-details>
  </div>
</div>
