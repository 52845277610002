import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@fgb/core';
import { ErrorStateService } from '@fgb/core';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'fgb-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  errorCode$: Observable<string | undefined>;
  constructor(
    private router: Router,
    public errorService: ErrorStateService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    if (this.authService.hasCurrentRefreshToken()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.errorCode$ = this.route.queryParams.pipe(
      map((query) => {
        const errorCode = query['errorCode'];
        if (errorCode === '400') {
          return 'error.code.upgrade.info';
        }
        if (errorCode === '401') {
          return 'product.code.upgrade.info';
        } else if (errorCode) {
          return 'login.error.code';
        } else {
          return;
        }
      })
    );
  }
}
