<fgb-navigation-indicator></fgb-navigation-indicator>

<ng-container *ngIf="!navigationSettings?.mobile.hidden">
  <fgb-mobile-nav-menu *ifScreenSize="screenType.Mobile"></fgb-mobile-nav-menu>
</ng-container>

<ng-container *ngIf="!navigationSettings?.desktop.hidden">
  <fgb-nav-bar *ifScreenSize="screenType.Desktop"></fgb-nav-bar>
</ng-container>

<div id="main" role="main">
  <router-outlet></router-outlet>
</div>

<fgb-footer></fgb-footer>
