<div class="d-none d-lg-flex banner" *ifScreenSize="screenSize.Desktop">
  <h1>{{ 'payment.page.title' | translate }}</h1>
</div>

<div class="bg-primary px-1" *ifScreenSize="screenSize.Mobile">
  <fgb-barcode [showCashBackToggle]="true"></fgb-barcode>
</div>

<div class="d-block" *ifScreenSize="screenSize.Mobile">
  <ul class="nav nav-secondary payment-nav mobile-nav">
    <a
      class="nav-item font-size-14 fw-bold primary-theme"
      [routerLink]="['/payment']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <!-- <label>{{ 'navbar.payment' | translate }}</label> -->
      <label>PAYMENT CARDS</label>
    </a>
    <a
      class="nav-item font-size-14 fw-bold primary-theme"
      [routerLink]="['/ecashhistory']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <!-- <label>{{ 'navbar.ecash.history' | translate }}</label> -->
      <label>TRANSACTION HISTORY</label>
    </a>
  </ul>
</div>

<!-- home nav bar  -->
<div class="d-block" *ifScreenSize="screenSize.Desktop">
  <fgb-home-nav-bar></fgb-home-nav-bar>
</div>

<div class="container d-none d-lg-block">
  <fgbcl-ecash-description></fgbcl-ecash-description>
  <fgbcl-card-token-list
    [regex]="regex"
    [provider]="provider"
    [redirectUrl]="'/cardtoken/result'"
    [failureUrl]="'/cardtoken/failure'"
  ></fgbcl-card-token-list>
</div>

<div class="container d-block d-lg-none">
  <fgbcl-card-token-list
    [regex]="regex"
    [provider]="provider"
    [redirectUrl]="'/cardtoken/result'"
    [failureUrl]="'/cardtoken/failure'"
  ></fgbcl-card-token-list>
  <fgbcl-ecash-description></fgbcl-ecash-description>
</div>

<div class="container d-none d-lg-block mt-4">
  <fgb-transaction-history
    [transactionTypes]="[transactionTypes.ecash, transactionTypes.payment]"
    [ecashView]="true"
  ></fgb-transaction-history>
</div>
