<div class="container">
  <!--header-->
  <div class="container h-100 shadow rounded mt-lg-4 mt-3">
    <div class="row">
      <div class="col-lg-6 col-12 subscription-header-banner"></div>
      <div class="col-lg-6 col-12">
        <h3 class="text-primary mt-2 font-size-22">
          <ng-container *ngIf="maxSubs > 0; else elseNoUpgrades">
            <span>You have</span> {{ activeSubs }}/{{ maxSubs }}
            <span>Digital Products</span>
          </ng-container>
          <ng-template #elseNoUpgrades>
            <span>No digital products available</span>
          </ng-template>
        </h3>
        <p class="font-size-14 fw-bold mb-0">NEW WAYS TO EARN POINTS!</p>
        <p class="font-size-12 text-info mb-lg-0">
          Note: Accumulation of points for new subscriptions begins the following game if a game is already underway.
        </p>
      </div>
    </div>
  </div>
  <!--membership options-->
  <div *ngIf="membershipPortalProducts.length && !foundMembership; else elseRenewals">
    <h3 class="mt-4 font-size-22">memberships</h3>
    <div class="row">
      <ng-container *ngFor="let membProduct of membershipPortalProducts">
        <div class="col-lg-6 col-12">
          <fgb-subscriptions-list-item [portalProduct]="membProduct"></fgb-subscriptions-list-item>
        </div>
      </ng-container>
    </div>
  </div>
  <!--renewals of memberships-->
  <ng-template #elseRenewals>
    <div *ngIf="foundMembership">
      <h3 class="mt-4 font-size-22">memberships</h3>
      <div class="row">
        <ng-container *ngFor="let product of purchasedProducts">
          <div class="col-lg-6 col-12" *ngIf="product.ProductType == 1">
            <fgb-subscriptions-list-item-renewals [subscribedProduct]="product"></fgb-subscriptions-list-item-renewals>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <!--subscription options-->
  <div *ngIf="subscriptionPortalProducts.length || foundSubscription">
    <h3 class="mt-4 font-size-22">Digital Products</h3>
    <div class="row">
      <!-- active portal products which are not purchased-->
      <ng-container *ngIf="notPurchasedActiveSubs.length > 0">
        <ng-container *ngFor="let subProduct of notPurchasedActiveSubs">
          <div class="col-12">
            <fgb-subscriptions-list-item [portalProduct]="subProduct"></fgb-subscriptions-list-item>
          </div>
        </ng-container>
      </ng-container>
      <!-- portal products which have been purchased -->
      <ng-container *ngIf="purchasedProducts.length > 0">
        <ng-container *ngFor="let product of purchasedProducts">
          <div class="col-12" *ngIf="product.ProductType == 3">
            <fgb-subscriptions-list-item-subscribed [subscribedProduct]="product"></fgb-subscriptions-list-item-subscribed>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <!--membership and subscription cart modal-->
  <div class="footer-sticky">
    <div class="footer-card my-3" *ngIf="items$ | async as items">
      <div class="card-body px-0" *ngIf="items.length"></div>
    </div>
  </div>

  <!--No new products or active products-->
  <ng-container *ngIf="allProducts == 0">
    <div>
      <img class="no-subs-image d-flex mx-auto" src="assets/images/placeholders/no-subscriptions.svg" />
      <h5 class="text-primary text-center mt-3 mb-0 fw-bold text-uppercase">SUBSCRIPTIONS</h5>
      <p class="text-info text-center font-size-14">Currently Unavailable</p>
    </div>
  </ng-container>
</div>
