<div class="banner" role="banner" *ifScreenSize="screenSize.Desktop">
  <h1 class="text-white">{{ 'terms.page.title' | translate }}</h1>
</div>

<ng-container *ifScreenSize="screenSize.Mobile">
  <div class="px-1 bg-primary">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="pt-4">
        <fgbcl-terms-and-conditions [type]="termsAndConditionsType" [defaultTerms]="defaultTemplate"></fgbcl-terms-and-conditions>
      </div>
    </div>
  </div>
</div>

<ng-template #defaultTemplate>
  <p class="terms-section-content text-start mb-3">Loading...</p>
</ng-template>
