<div class="full-page w-100">
  <div class="login-container w-100 h-100 d-flex flex-column align-items-center">
    <div class="login-logo">
      <img class="" src="assets/images/login-registration/sjeq-maintenance-logo.png" alt="" />
    </div>
    <div class="login-info d-flex flex-column">
      <div class="login-title text-primary">{{ 'login.page.title' | translate }}</div>

      <div class="login-text" [innerHTML]="'login.page.description' | translate | markdownTranslate"></div>

      <fgb-tm-user-login></fgb-tm-user-login>

      <ng-container *ngIf="errorCode$ | async as errorCode">
        <div class="login-account-error text-center my-4" [innerHTML]="errorCode | translate | markdownTranslate"></div>
      </ng-container>
    </div>
  </div>
</div>
