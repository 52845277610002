<div class="d-lg-none d-block">
  <div class="bg-primary px-1">
    <fgb-barcode [showCashBackToggle]="true"></fgb-barcode>
  </div>
</div>

<div class="d-block d-lg-none">
  <ul class="nav nav-secondary transaction-nav">
    <a
      class="nav-item font-size-14 fw-bold"
      [routerLink]="['/payment']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <!-- <label class="ps-1">{{ 'nav.ecash' | translate }}</label> -->
      <label class="ps-1">PAYMENT CARDS</label>
    </a>
    <a
      class="nav-item font-size-14 fw-bold"
      [routerLink]="['/ecashhistory']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <!-- <label class="ps-1">{{ 'nav.ecash.history' | translate }}</label> -->
      <label class="ps-1">TRANSACTION HISTORY</label>
    </a>
  </ul>
</div>

<div class="container mt-4">
  <h3 class="transaction-title">TRANSACTIONS</h3>
  <fgb-transaction-history
    [transactionTypes]="[transactionTypes.ecash, transactionTypes.payment]"
    [ecashView]="true"
  ></fgb-transaction-history>
</div>
