<div class="full-page w-100">
  <div class="maintenance-container w-100 h-100 d-flex flex-column align-items-center">
    <div class="maintenance-logo">
      <img class="" src="assets/images/login-registration/sjeq-maintenance-logo.png" alt="" />
    </div>
    <div class="maintenance-info text-center">
      <span class="material-icons text-primary"> engineering </span>
      <!-- <div class="maintenance-will-be-back text-secondary">{{ 'maintenancepage.title' | translate }}</div> -->
      <div class="maintenance-will-be-back text-primary">We’ll be back soon</div>
      <!-- <div
        class="maintenance-text m-3 text-center"
        [innerHTML]="'maintenancepage.currentlyunavailable.text' | translate | markdownTranslate"
      ></div> -->
      <div class="maintenance-text m-3 text-center">
        We’re currently undergoing scheduled maintenance. We should be back shortly!
      </div>
    </div>
  </div>
</div>
