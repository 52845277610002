import { Component, Input, OnInit } from '@angular/core';
import { MixedLoyaltyQuery, RewardsWalletItem } from '@fgb/core';

@Component({
  selector: 'fgb-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.scss'],
})
export class WalletPageComponent implements OnInit {
  @Input() walletItems: RewardsWalletItem[];

  constructor(private mixedLoyaltyQuery: MixedLoyaltyQuery) {}

  async ngOnInit() {
    await this.mixedLoyaltyQuery.walletFetch();
  }
}
