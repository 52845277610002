<div class="card rounded">
  <div *ngIf="member$ | async as member">
    <div class="">
      <label class="mb-0">{{ 'account.details.dob' | translate }}</label>
      <div class="d-flex bg-muted p-2 m-0 rounded">
        <span class="icon material-icons" aria-hidden="true">date_range</span>
        <span class="ms-3 font-size-12">{{ member.DOB | date : 'MMM d, yyy' }}</span>
      </div>
    </div>
    <div>
      <label class="mb-0 personal-info">{{ 'account.details.email' | translate }}</label>
      <div class="d-flex bg-muted p-2 m-0 rounded">
        <span class="icon material-icons" aria-hidden="true">email</span>
        <span class="ms-3 font-size-12">{{ member.EmailAddress1 }}</span>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
