<div class="text-primary" *ngIf="barcode$ | async as barcode">
  <div class="p-2 pt-4 pb-3 d-flex flex-wrap flex-column justify-content-center align-items-start">
    <ng-container *ngIf="card$ | async as card">
      <!-- <div class="qr mx-3">
        <qrcode [qrdata]="barcode.Barcode" [width]="125" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
      </div> -->
      <ng-container *ngIf="member$ | async as member">
        <div class="d-flex justify-content-between align-items-center text-white pb-3 w-100" *ngIf="card$ | async as card">
          <!--Name and ID-->
          <div class="card-id text-start">
            <h5 class="mb-1 font-size-18 card-name w-100" *ngIf="memberDetails$ | async as memberDetails">
              {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
            </h5>
            <div class="d-flex font-size-12">
              <div>{{ card.ProductCodeDisplayName || card.ProductCode }}</div>
              <div class="px-1">-</div>
              <div>{{ card.ExternalRefNumber }}</div>
            </div>
          </div>

          <div
            class="wallet d-flex flex-row justify-content-center align-items-center rounded"
            [ngClass]="barcodeType == 'inProductCode' ? 'in-product-code' : 'not-in-product-code'"
            *ngIf="displayWallet"
          >
            <span class="icon material-icons text-white font-size-18"> qr_code_2</span>
            <!-- <p class="text-white m-0" (click)="onClickYourRewards()">{{ 'account.reward' | translate }}</p> -->
            <p class="text-white font-size-14 m-0 fw-bold" (click)="onClickYourRewards()">Wallet</p>
          </div>
        </div>

        <!-- Barcode -->
        <div
          *ngIf="displayQrCode"
          class="qrcode-container d-flex flex-wrap flex-column justify-content-around align-items-center rounded bg-white mt-2"
        >
          <div class="barcode-wrapper qr mx-3 mb-2 text-black d-flex flex-column">
            <ng-container *ngIf="barcode.Barcode; else loadingRef">
              <qrcode [width]="135" [qrdata]="barcode.Barcode" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
              <!-- <p class="m-0">{{ 'account.barcode.scanqr' | translate }}</p> -->
              <p class="m-0 text-center">Scan Member Barcode</p>
            </ng-container>
          </div>
        </div>
        <!-- Not Owner Barcode Body -->
        <div
          class="barcode-content-container text-white w-100 bg-white text-black d-flex justify-content-evenly align-items-center p-2"
        >
          <ng-container *ngIf="card$ | async as card">
            <ng-container *ngIf="card.DiscountLevel > 0">
              <div class="card-account-no text-center" *ngIf="card.DiscountLevel > 0">
                <div class="font-size-14 fw-bold">{{ card.DiscountLevel + '%' }}</div>
                <label class="font-size-12 m-o">{{ 'member.card.discount' | translate }}</label>
              </div>

              <div class="line"></div>
            </ng-container>

            <ng-container *ngIf="card.RetailDiscountLevel > 0">
              <div class="card-account-no text-center">
                <div class="font-size-14 fw-bold">{{ card.RetailDiscountLevel + '%' }}</div>
                <label class="font-size-12 m-o">{{ 'member.card.retail.discount' | translate }}</label>
              </div>

              <div class="line"></div>
            </ng-container>
          </ng-container>

          <div class="card-account-no text-center" *ngIf="ecash$">
            <div class="font-size-14 fw-bold">{{ ecash$ | async | FortressCurrencyPipe }}</div>
            <label class="font-size-12 m-o">{{ 'member.card.ecash.balance' | translate }}</label>
          </div>
        </div>
        <!-- <fgb-payment-button style="width: 100%"></fgb-payment-button> -->
      </ng-container>
    </ng-container>
  </div>
</div>
