import { Component, OnInit } from '@angular/core';
import {
  BalanceQuery,
  CardToken,
  CardTokenQuery,
  config,
  MemberDetails,
  MemberQuery,
  ProviderName,
  TransactionType,
} from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Observable } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';
import { Regex } from 'src/app/shared/utilities/regex';

@Component({
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent implements OnInit {
  defaultCardToken$: Observable<CardToken>;

  memberDetails$: Observable<MemberDetails | undefined>;
  combinedCard$: Observable<number | undefined>;
  loyaltyPointsCard$: Observable<number | undefined>;
  populations = Populations;

  transactionStartDate: Date;
  transactionEndDate: Date;
  transactionTypes = TransactionType;
  memberId: string;

  regex = Regex;
  provider = ProviderName.FreedomPay;

  screenSize = ScreenType;

  constructor(private cardTokenQuery: CardTokenQuery, private memberQuery: MemberQuery, private balanceQuery: BalanceQuery) {}

  ngOnInit() {
    this.memberId = this.memberQuery.getUserContext().memberId;
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.loyaltyPointsCard$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.transactionEndDate = new Date();
    this.transactionStartDate = new Date();
    this.transactionStartDate.setDate(new Date(this.transactionStartDate).getDate() - 1);
    this.defaultCardToken$ = this.cardTokenQuery.selectDefaultCard();
  }
}
