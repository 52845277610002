<div class="full-page bg-primary w-100">
  <div class="col-12 register-panel">
    <div class="overflow-hidden p-0">
      <div class="row">
        <div class="user-login-panel col-12 text-start bg-white">
          <div class="d-flex justify-content-center mb-4">
            <img class="text-logo" src="assets/images/text-logo.svg" alt="" />
          </div>
          <div class="container">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
