<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex justify-content-between">
    <a class="nav-item fw-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <img alt="" class="home-logo" src="assets/images/primary-logo.png" draggable="false" />
    </a>

    <!--Notifications-->
    <div class="nav-item">
      <!-- <fgb-notification-button></fgb-notification-button> -->
    </div>
    <button
      attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
      class="nav-item fw-bold navbar-toggler m-0 px-2"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="icon material-icons material-mobile-nav">menu</span>
    </button>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown">
    <div class="d-flex flex-column p-1 mobile-nav-menu-content">
      <!-- Close Button -->
      <button
        class="close-button position-absolute border-0"
        attr.aria-label="{{ 'modal.close' | translate }}"
        (click)="isCollapsed = !isCollapsed"
      >
        <i class="material-icons close-btn text-grey">cancel</i>
      </button>
      <div>
        <img alt="" class="sjeq-logo" src="assets/images/primary-logo.png" draggable="false" />
      </div>
      <fgb-barcode [displayWallet]="false"></fgb-barcode>

      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container">
        <a
          class="nav-item fw-bold large-item"
          [routerLink]="['']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <div class="nav-text fw-bold">{{ 'nav.home' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>
        <a
          class="nav-item fw-bold large-item"
          [routerLink]="['/account']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <div class="nav-text fw-bold">{{ 'nav.account' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <!-- <a class="nav-item" [routerLink]="['/payment']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <div class="nav-text fw-bold">{{ 'nav.payment' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a> -->

        <!-- <a class="nav-item" [routerLink]="['/faq']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <div class="nav-text fw-bold">{{ 'nav.faq' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a> -->

        <!-- <a class="nav-item" [routerLink]="['/contact']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <div class="nav-text fw-bold">{{ 'nav.contact.us' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a> -->

        <!-- <a class="nav-item" [routerLink]="['/terms']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <div class="nav-text fw-bold">{{ 'nav.terms' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a> -->

        <a class="nav-item logout-link-div" (click)="logout()">
          <div class="nav-text fw-bold">{{ 'nav.log.out' | translate }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
