<div class="banner" role="banner" *ifScreenSize="screenSize.Desktop">
  <!-- <h1>{{ 'faq.page.title' | translate }}</h1> -->
  <h1 class="text-white">FAQS</h1>
</div>

<ng-container *ifScreenSize="screenSize.Mobile">
  <div class="px-1 bg-primary">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<div class="container">
  <fgbcl-faq-section></fgbcl-faq-section>
</div>
