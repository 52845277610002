<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div class="member-card bg-primary rounded text-white">
    <div class="d-flex flex-column justify-content-between h-100">
      <!--Name and ID-->
      <div class="d-flex justify-content-between align-items-center">
        <!--Name and ID-->
        <div class="card-id text-start">
          <p class="mb-0 card-name text-uppercase" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </p>
          <div class="d-flex font-size-12">
            <div>{{ memberCard.ProductCodeDisplayName || memberCard.ProductCode }}</div>
            <div class="px-1">-</div>
            <div>{{ memberCard.ExternalRefNumber }}</div>
          </div>
        </div>
        <!--Logo-->
        <img alt="" class="card-logo" src="assets/images/primary-logo.png" draggable="false" />
      </div>

      <!--Balances-->
      <div class="d-flex justify-content-start align-items-center">
        <ng-container *ngIf="memberCard$ | async as card">
          <ng-container *ngIf="card.DiscountLevel > 0">
            <div class="card-points text-start pe-4">
              <label class="font-size-14 mb-1">{{ card.DiscountLevel + '%' }}</label>
              <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
            </div>

            <div class="line"></div>
          </ng-container>
          <ng-container *ngIf="card.RetailDiscountLevel > 0">
            <div class="card-points text-start px-4 pe-4" *ngIf="card.RetailDiscountLevel > 0">
              <label class="font-size-14 mb-1">{{ card.RetailDiscountLevel + '%' }}</label>
              <div class="font-size-12">{{ 'member.card.retail.discount' | translate }}</div>
            </div>

            <div class="line me-4"></div>
          </ng-container>
        </ng-container>

        <div class="card-id text-start" *ngIf="ecash$">
          <label class="font-size-14 mb-1">{{ ecash$ | async | FortressCurrencyPipe }}</label>
          <div class="font-size-12">{{ 'member.card.credit.balance' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- <fgb-payment-button></fgb-payment-button> -->
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
