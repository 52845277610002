<ng-container *ngIf="transactions$ | async as transactions">
  <ng-container *ngIf="transactions$ | async as transactions">
    <div *ngFor="let day of transactions | keyvalue : keepOrder">
      <h5 class="transaction-date mt-4 mb-3 text-primary">{{ day.key }}</h5>
      <div class="mb-0 mb-lg-5">
        <div class="card my-3 py-2 px-4 border" *ngFor="let transaction of day.value">
          <div class="row w-100">
            <div class="col-8 text-start d-flex align-items-center flex-row">
              <span class="right-arrow icon material-icons" aria-hidden="true">chevron_right</span>
              <div>
                <div class="fw-bold text-black transaction-text font-size-12">
                  {{ transaction.TransactionDescription }}
                </div>

                <div class="transaction-subtext fw-bold">
                  {{ transaction.TransactionDateUTC | UtcToLocaltimePipe : 'hh:mm a' | uppercase }}
                </div>
              </div>
            </div>
            <div class="col-4 text-end pt-2">
              <div
                class="fw-bold transaction-text text-capitalize"
                [ngClass]="transaction.Amount >= 0 ? 'text-success' : 'text-danger'"
              >
                {{ transaction.Amount >= 0 ? '+' : ''
                }}{{ transaction.Amount | FortressCurrencyPipe : (transactionType === transactionTypes.loyalty ? 'PTS' : 'USD') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!transactions || !hasTransactions">
      <div class="w-100 text-center no-items pt-4">
        <div class="no-results-placeholder-description card border">
          {{ 'transactions.page.placeholder' | translate }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<div *ngIf="loading" class="mt-4">
  <fgbcl-loading></fgbcl-loading>
</div>
