<div class="footer-divider"></div>
<div
  role="contentinfo"
  class="footer text-white text-center bg-primary text-black d-flex align-items-center justify-content-center w-100"
>
  <div class="footer-content mt-lg-3 mt-2">
    <img alt="" class="footer-logo mb-2" src="assets/images/primary-logo.png" draggable="false" />
    <p
      class="text-white font-size-14 text-center club-rights-footer"
      [innerHTML]="'footer.club.rights' | translate | markdownTranslate"
    ></p>
  </div>
</div>
