<div class="banner" role="banner" *ifScreenSize="screenSize.Desktop">
  <!-- <h1>{{ 'contact.us.page.title' | translate }}</h1> -->
  <h1 class="text-white">CONTACT US</h1>
</div>

<ng-container *ifScreenSize="screenSize.Mobile">
  <div class="px-1 bg-primary">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<fgbcl-contact-us></fgbcl-contact-us>
